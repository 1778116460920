////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// react-toastify

@import "~react-toastify/dist/ReactToastify.css";

.Toastify__toast
{
    padding: 0px !important;
    border-radius: $border-radius !important;
}

.Toastify__toast--default 
{
    background: none !important;
    color: transparent !important;
}

.Toastify__toast-container
{
    padding: 0px !important;
}

.Toastify__toast-container--bottom-right 
{
    bottom: 0 !important;
    //right: $spacer;
    // Maps to bootstrap's m-3
    right: map-get($spacers, 3)
}
