code
{
    color: white;
}

.table-dark.table-hover tbody tr:hover
{
    background-color: $msp-colors-highlight;
}

.table-light.table-hover tbody tr:hover
{
    background-color: $gray-400;
}
