// Common theme

$msp-colors-primary: theme-color("primary");
$msp-colors-secondary: theme-color("secondary");
$msp-colors-highlight: lighten($gray-800, 14%);
$msp-colors-dark: theme-color("dark");
$msp-colors-lightdark: lighten(theme-color("dark"), 4%);

// Light theme

$msp-theme-light-title: black;
$msp-theme-light-background: white;
$msp-theme-light-dark: $msp-colors-secondary;
$msp-theme-light-softdark: lighten($msp-theme-light-dark, 4%);

// Dark theme

$msp-theme-dark-title: white;
$msp-theme-dark-background: #626874;
$msp-theme-dark-dark: theme-color("dark");
$msp-theme-dark-softdark: lighten($msp-theme-dark-dark, 4%);

// Others

$msp-transition-default-duration: 0.4s;
