body 
{
	margin: 0;
  	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

code 
{
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Disable the outline border for tables (in particular with react-bootstrap-table2 while sorting)
th:focus 
{ 
	outline: 0; 
}

table
{
	margin-bottom: 0 !important;
}