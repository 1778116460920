@keyframes msp-spin-animation
{
	0% 
	{
		transform: rotate(0deg);
	}

	100% 
	{
		transform: rotate(359deg);
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.msp-bg-lightdark
{
	background-color: $msp-colors-lightdark;
}

.msp-center
{
	justify-content: center;
    align-items: center;    
}
/*
.msp-dialog .modal-header
{
	border-bottom-color: $msp-colors-primary;
}

.msp-dialog .modal-header,
.msp-dialog .modal-header .close
{
	color: white;	
	background-color: transparent !important;	
	text-shadow: 0 1px 0 #000;
	opacity: 1;
}

.msp-dialog .modal-content
{
	background-color: $msp-colors-highlight;
	color: white;	
}

.msp-dialog .modal-footer
{
	border-top-color: $msp-colors-primary;
}
*/
.msp-display-center
{
	display: flex;
  	align-items: center;
}

.msp-disable-selection 
{
	-webkit-user-select: none;  /* Chrome all / Safari all */
	-moz-user-select: none;     /* Firefox all */
	-ms-user-select: none;      /* IE 10+ */
	user-select: none;          /* Likely future */      
}

.msp-spin
{
	animation: msp-spin-animation 2s infinite linear;
}

// Add this class to NavDropdown to remove the arrow indicator
.msp-removecaret .dropdown-toggle::after, .dropdown-toggle::before
{
    display: none;
}

.msp-table-large
{
	font-size: 22px;
}

.msp-table-auto
{
	table-layout: auto !important;
}

.msp-table-auto th,
.msp-table-auto td 
{
	white-space: nowrap !important;	
}

.msp-transition
{
	transition: $msp-transition-default-duration;
}

.msp-table-rounded
{
	@extend .rounded-lg;
}

// Disable the top border on rounded tables 
.msp-table-rounded th
//, .msp-table-rounded > tbody > tr:first-child td
{
	border-top: 0px !important;
}

.msp-table-rounded tr:first-child td 
{
    border-top: none;
}

.msp-table-rounded tr:last-child td
{
    border-bottom: none;
}

.msp-table-rounded > thead > tr:first-child th:first-child
{
	border-top-left-radius: $border-radius-lg;
}

.msp-table-rounded > thead > tr:first-child th:last-child
{
	border-top-right-radius: $border-radius-lg;
}

.msp-table-rounded > tbody > tr:first-child td:first-child
{
	border-top-left-radius: $border-radius-lg;
	//background-color: green;
}

.msp-table-rounded > tbody > tr:first-child td:last-child
{
	border-top-right-radius: $border-radius-lg;
	//background-color: red;
}

.msp-table-rounded > tbody > tr:last-child td:first-child
{
	border-bottom-left-radius: $border-radius-lg;
	//background-color: green;
}

.msp-table-rounded > tbody > tr:last-child td:last-child
{
	border-bottom-right-radius: $border-radius-lg;
	//background-color: red;
}

// Add the margin around the selection checkbox
.msp-table-rounded td > .selection-input-4, 
.msp-table-rounded th > .selection-input-4 
{
    margin: map-get($spacers, 1) !important;
}

@keyframes ms-opacity-pulse-keyframes 
{
    0% { transform: scale(0.9); opacity: 0.65; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0.9); opacity: 0.65; }
}

.ms-opacity-pulse
{
	-webkit-animation: ms-opacity-pulse-keyframes 2s infinite ease-in-out;
    -o-animation: ms-opacity-pulse-keyframes 2s infinite ease-in-out;
    -ms-animation: ms-opacity-pulse-keyframes 2s infinite ease-in-out;
    -moz-animation: ms-opacity-pulse-keyframes 2s infinite ease-in-out;
    animation: ms-opacity-pulse-keyframes 2s infinite ease-in-out;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.msp-hide-focus:focus
{
	outline: none !important;
	box-shadow: none !important;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.msp-w25px
{
	width: 25px !important;
}

.msp-w50px
{
	width: 50px !important;
}

.msp-w100px
{
	width: 100px !important;
}

.msp-w125px
{
	width: 125px !important;
}

.msp-w150px
{
	width: 150px !important;
}

.msp-w200px
{
	width: 200px !important;
}

.msp-w225px
{
	width: 225px !important;
}

.msp-w250px
{
	width: 250px !important;
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

.msp-modal-50w
{ 
	max-width: 50% !important; 
}

.msp-modal-90w
{ 
	max-width: 90% !important; 
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
